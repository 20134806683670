import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import useEventListener from "@use-it/event-listener"

import useWindowSize from "../hooks/useWindowSize"

const Images = styled.div`
  white-space: nowrap;
  display: block;
  @media screen and (max-width: 768px) {
      width: 100vw;
      overflow-x: scroll;
    }
`

const Image = styled.div`
  display: inline-block;
  img {
    height: 500px;
    padding-right: 20px;
    @media screen and (max-width: 768px) {
      height: auto;
      width: 100vw;
    }
  }
`

const EventImages = ({ gallery }) => {
  const [coords, setCoords] = useState([0, 0])
  const [scroll, setScroll] = useState(0)
  const [imagesWidth, setImagesWidth] = useState(0)

  const ref = useRef(null)

  const size = useWindowSize()

  useEventListener("mousemove", ({ clientX, clientY }) => {
    setCoords([clientX, clientY])
  })

  useEventListener("scroll", () => {
    setScroll(window.scrollY)
  })

  useEffect(() => {
    const width = ref.current ? ref.current.scrollWidth : 0
    setImagesWidth(width)
  })

  const move = "-" + (coords[0] / size[0]) * (imagesWidth - size[0] - 20) + "px"

  return (
    <Images
      ref={ref}
      style={{
        position: scroll > 485 ? "fixed" : "absolute",
        top: scroll > 485 ? "-485px" : 0,
        left: move,
      }}
    >
      {gallery &&
        gallery.map((e, i) => {
          return (
            <Image key={i}>
              <img src={e.localFile.publicURL} />
            </Image>
          )
        })}
    </Images>
  )
}

export default EventImages

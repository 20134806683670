import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import EventImages from "../components/EventImages"

const EventConent = styled.div`
  margin: 520px auto;
  width: 75%;
  max-width: 900px;
  font-size: 1.6rem;
  line-height: 1.8;
  padding-bottom: calc(100vh - 320px);
  position: relative;
  z-index: 10000;
  @media screen and (max-width: 768px) {
      font-size: 1.2rem;
      margin-top: 300px
    }
  span {
    text-decoration: none !important;
  }
  a {
    color: red;
    text-decoration: underline;
    text-decoration-style: wavy;
  }
  h1 {
    font-weight: 200;
    /* letter-spacing: 0.15em; */
    /* text-transform: uppercase; */
    font-size: 8.8rem;
    line-height: 0.9;
    margin-top: -4rem;
    position: relative;
    z-index: 10000;
    color: red;
    font-style: italic;
    @media screen and (max-width: 768px) {
      font-size: 4rem;
    }
  }
`

const EventLogo = styled.div`
  padding: 1rem 5rem 3rem;
`

const postTemplate = props => {
  return (
    <Layout page={props.pageContext.slug}>
      <SEO title={props.pageContext.title} />
      <EventImages gallery={props.pageContext.gallery}></EventImages>

      <EventConent>
        <h1>
          <span dangerouslySetInnerHTML={{ __html: props.pageContext.title }}></span>
        </h1>
        {props.pageContext.logo && (
          <EventLogo
            dangerouslySetInnerHTML={{ __html: props.pageContext.logo }}
          ></EventLogo>
        )}

        <div
          dangerouslySetInnerHTML={{ __html: props.pageContext.content }}
        ></div>
      </EventConent>
    </Layout>
  )
}

export default postTemplate
